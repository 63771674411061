<template>
  <div class="whole">
    <Nav></Nav>
    <div class="buy-con desktop-present">
      <div class="buy-title">已购买的图片列表</div>
      <div class="down-btn">
        <el-checkbox
          @change="onAllSelected"
          v-model="allCheck"
          :indeterminate="isIndeterminate"
          >全选</el-checkbox
        >
        <el-button type="primary" @click="onDownAll"
          >下载所勾选的{{ selectedLength }}图片</el-button
        >
      </div>
      <div class="pager-container">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="pager.currentPage"
          :page-size="pager.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pager.total"
        ></el-pagination>
      </div>
      <div class="buy-list">
        <div
          class="buy-item"
          v-for="(k, index) in alreadyBuyList"
          :key="'buy_' + index + k.id"
        >
          <el-checkbox
            class="selected"
            v-model="k.selectedPic"
            @change="onChangeSelect"
          ></el-checkbox>
          <div
            class="buy-img"
            :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
          ></div>
          <div class="buy-btn">
            <el-button size="small" @click="onDownPic(k.orderPicId)"
              >下载</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- buyPicUrl -->
    <form :action="buyPicUrl" ref="buyPicForm" method="POST" target="_blank">
      <input type="hidden" name="orderPicIds" :value="picIdsString" />
    </form>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      alreadyBuyList: [],
      buyPicUrl: "",
      picIdsString: "",
      selected: [],
      selectedLength: 0,
      allCheck: false, //默认全选的状态
      isIndeterminate: false, // 半选的状态
      pager: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
    };
  },
  created() {
    var thiz = this;
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        this.searchStores = data.searchStores;
      });
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      RD.myPost()
        .listPurchasedPic(thiz.pager.currentPage, 20)
        .then((data) => {
          data.list.forEach((k) => {
            k.imgURL = RD.pic().id(k.id).sImage_URL();
            k.selectedPic = false;
          });
          thiz.alreadyBuyList = data.list;
          thiz.pager = data.pager;
        });
    },
    // 下载图片
    onDownPic(id) {
      var thiz = this;
      var picIds = [];
      picIds.push(id);
      thiz.picIdsString = JSON.stringify(picIds);
      console.log("pic", picIds);
      thiz.buyPicUrl = RD.purchaseOrder().downloadBatchByOrderPicId_URL(picIds);
      thiz.$nextTick(() => {
        thiz.$refs.buyPicForm.submit();
      });
    },
    // 下载所勾选的图片
    onDownAll() {
      var thiz = this;
      if (thiz.selected.length == 0) {
        thiz.$message("请先勾选要下载的图片");
      } else {
        var selectIds = [];
        thiz.alreadyBuyList.forEach((k) => {
          if (k.selectedPic == true) {
            selectIds.push(k.orderPicId);
          }
        });
        thiz.picIdsString = JSON.stringify(selectIds);
        console.log("lkl", selectIds);
        thiz.buyPicUrl =
          RD.purchaseOrder().downloadBatchByOrderPicId_URL(selectIds);
        thiz.$nextTick(() => {
          thiz.$refs.buyPicForm.submit();
        });
      }
    },
    // 勾选变化
    onChangeSelect() {
      var thiz = this;
      thiz.selected = [];
      thiz.alreadyBuyList.forEach((k) => {
        if (k.selectedPic == true) {
          thiz.selected.push(k.id);
        }
      });
      thiz.selectedLength = thiz.selected.length;
      if (thiz.selected.length >= 1) {
        thiz.hasLength = true;
      }
      if (thiz.selected.length == thiz.alreadyBuyList.length) {
        thiz.allCheck = true;
        thiz.isIndeterminate = false;
      } else if (thiz.selected.length == 0) {
        thiz.allCheck = false;
        thiz.isIndeterminate = false;
      } else if (thiz.selected.length >= 1) {
        thiz.isIndeterminate = true;
      }
    },
    // 全选
    onAllSelected() {
      var thiz = this;
      if (thiz.allCheck) {
        var allSelected = [];
        thiz.alreadyBuyList.forEach((k) => {
          k.selectedPic = true;
          allSelected.push(k.id);
        });
        thiz.selectedLength = allSelected.length;
        if (allSelected.length > 1) {
          thiz.hasLength = true;
        }
        thiz.selected = allSelected;
        if (thiz.selected.length == thiz.alreadyBuyList.length) {
          thiz.allCheck = true;
          thiz.isIndeterminate = false;
        }
      } else {
        thiz.selected = [];
        thiz.isIndeterminate = false;
        thiz.selectedLength = 0;
        thiz.allCheck = false;
        thiz.alreadyBuyList.forEach((k) => {
          k.selectedPic = false;
        });
      }
    },
    handleCurrentChange(val) {
      var thiz = this;
      thiz.pager.currentPage = val;
      thiz.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.whole {
  .buy-con {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 48.9rem);
    background-color: #f1f1f1;
    position: relative;
    .buy-title {
      height: 5rem;
      line-height: 5rem;
      text-align: left;
      font-size: 1.6rem;
      margin-left: 0.5rem;
    }
    .down-btn {
      height: 4rem;
      text-align: left;
      .el-button {
        margin-left: 1.3rem;
        height: 4rem;
      }
    }
    .pager-container {
      height: 6rem;
      text-align: right;
    }
    .buy-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .buy-item {
        width: 27rem;
        height: 22rem;
        margin-right: 2rem;
        margin-bottom: 3rem;
        position: relative;
        .selected {
          position: absolute;
          left: 0rem;
          top: -2rem;
          ::v-deep .el-checkbox__inner {
            border: 1px solid red;
          }
        }
        .buy-img {
          width: 100%;
          height: 80%;
          background-size: cover;
        }
        .buy-btn {
          height: 20%;
          .el-button {
            margin-top: 0.3rem;
            height: 3rem;
          }
        }
      }
    }
  }
}
</style>